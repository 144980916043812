<template>
  <div>
    <CRow>
      <CCol col="12">
           <CCard class="cards">
          <form id="createRole" @submit="createRole" >
          <CCardHeader>
            <CCol col="6" class="styleHeader">{{ $t('message.createRole')}}  </CCol>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <CInput  :label=" $t('message.name')"
                  id="name"
                  v-model="role.name"
                  type="text"
                  class="required"
                  :placeholder="$t('message.name')"
                  :invalidFeedback="errors.name"
                  required
                />
              </CCol>
              <CCol sm='6'>
                <div class='form-group'>
                    <label for='role'>
                      {{$t('message.role')}}
                      <span class='star'>*</span>
                    </label>
                    <div>
                      <multiselect
                        class='required'
                        v-model='SelectedPermissions'
                        id='id'
                        :options='permissions'
                        :multiple="true"
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='name'
                        required='required'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>
                    </div>
                  </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" size="sm" color="primary" >
            <CIcon name="cil-check-circle"/> {{$t('message.save')}}</CButton>
              &emsp;
            <CButton type="reset" size="sm" color="danger">
            <CIcon name="cil-ban"/> {{$t('message.clear')}} </CButton>
          </CCardFooter>
          </form>/
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import $ from 'jquery'
import swal from 'sweetalert'
export default {
  name: 'createCity',
  data: function () {
    return {
      role: {},
      permissions: [],
      SelectedPermissions: [],
      errors: []
    }
  },
  created () {
    const permissions = []
    this.$http
      .get(`${this.$hostUrl}permissions`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          permissions.push({ id: value.id, name: value.name.replace(':', ' ') })
        })
        this.permissions = permissions
      })
  },
  methods: {
    async createRole (e) {
      e.preventDefault()
      const formData = new FormData()
      if (this.SelectedPermissions.length <= 0) {
        swal(this.$t('message.permissionsAreRequired'))
        return 0
      }
      formData.append('name', this.role.name)
      const permissions = []
      $.each(this.SelectedPermissions, function (key, value) {
        permissions.push({ id: value.id })
      })
      formData.append('permissions', JSON.stringify(permissions))
      this.postRequest(formData, this.$hostUrl + 'roles', '/roles')
    }
  }
}

</script>
